import React, { Component } from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

class Contact extends Component {
  constructor(props) {
    super(props)
    this.state = {
      contactEmail: "",
      contactMessage: "",
    }

    this._handleSubmit = this._handleSubmit.bind(this)
    this._handleChange = this._handleChange.bind(this)
    this._handleChangeMsg = this._handleChangeMsg.bind(this)
  }

  // Change state of input field so text is updated while typing
  _handleChange(e) {
    this.setState({
      contactEmail: e.target.value,
    })
  }

  // Change state of input field so text is updated while typing
  _handleChangeMsg(e) {
    this.setState({
      contactMessage: e.target.value,
    })
  }

  _handleSubmit(e) {
    e.preventDefault()
    this.setState({
      contactEmail: "",
      contactMessage: "",
    })

    e.preventDefault()
    var action = "https://getform.io/f/bdf95df8-1df2-452f-9ea0-a5a8dcae8b1e"

    const requestOptions = {
      type: "POST",
      url: action,
      crossDomain: true,
      data: {
        form_email: this.state.contactEmail,
        form_msg: this.state.contactMessage,
      },
      dataType: "json",
      contentType: "multipart/form-data",
      processData: false,
      headers: {
        Accept: "application/json",
      },
    }

    fetch(action, requestOptions)
      .then(response => response.json())
      .then(data => alert("rrrrrrrrrrrrrr"))
  }

  render() {
    return (
      <div className="contact" id="contact">
        <div className="filter">
          <form className="form" onSubmit={this._handleSubmit} id="formContact">
            <label>Emaizerzrl</label>
            <input
              id="formEmail"
              type="email"
              name="formEmail"
              value={this.state.contactEmail}
              onChange={this._handleChange}
              required
            />
            <label>Meddelande</label>
            <textarea
              id="formMsg"
              name="formMsg"
              rows="8"
              cols="40"
              value={this.state.contactMessage}
              onChange={this._handleChangeMsg}
              required
            ></textarea>
            <input
              type="submit"
              value="Submit"
              className="btn--cta"
              id="btn-submit"
            />
          </form>
        </div>
      </div>
    )
  }
}

export default Contact

export const pageQuery = graphql`
  query Contact {
    allTaxonomyTermMarques {
      edges {
        node {
          path {
            alias
          }
          id
          name
          relationships {
            field_image {
              localFile {
                childImageSharp {
                  fluid {
                    base64
                    tracedSVG
                    aspectRatio
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    sizes
                    originalImg
                    originalName
                    presentationWidth
                    presentationHeight
                  }
                }
              }
            }
          }
        }
      }
    }
    allNodeActualites(sort: { fields: created, order: DESC }) {
      edges {
        node {
          id
          title
          path {
            alias
          }
          field_description
          relationships {
            field_image {
              localFile {
                childImageSharp {
                  fluid {
                    base64
                    tracedSVG
                    aspectRatio
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    sizes
                    originalImg
                    originalName
                    presentationWidth
                    presentationHeight
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
